export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      WORK:'WORK',
      WORK_TYPE: 'WORK_TYPE',
      WORK_STATUS:'WORK_STATUS',
      USER:'USER',
      MESSAGE:'MESSAGE',
      FOUNDATION:'FOUNDATION',
    }
  }
}
