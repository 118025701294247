import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Login } from '../model/login';
import { Token } from '../model/token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  adminToken: Token = new Token();

  constructor(@Inject("apiUrl") private apiUrl: string, private httpClient: HttpClient, private router: Router, private toastrService: ToastrService) { }

  isAuthenticate() {
    if (localStorage.getItem("adminToken")) {
      return true;
    }
    return false;
  }

  async login(adminLogin: Login): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let api = this.apiUrl + "auth/Login";
      this.httpClient.post(api, adminLogin).subscribe(
        (res: any) => {
          this.adminToken = res.data;
          localStorage.setItem("adminToken", this.adminToken.accessToken);
          this.toastrService.success("Giriş İşlemi Başarılı");
          resolve(true);
        },
        (err) => {
          this.toastrService.error(err);
          resolve(false);
        }
      );
    });
  }

  logout() {
    localStorage.removeItem("adminToken");
    setTimeout(() => {
      this.router.navigate(["/login"])
    }, 100);
    
    this.toastrService.success("Çıkış İşlemi Başarılı")
  }
}
