import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Login } from '../model/login';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  passwordTextType:boolean;
  coreConfig:any;
  _unsubscribeAll: Subject<any>;

  login:Login=new Login();

  isLoginButtonActive: boolean = true;

  constructor(private _coreConfigService: CoreConfigService,private authService:AuthService,private router: Router) { }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  async signIn() {
    this.isLoginButtonActive = false;
    const result = await this.authService.login(this.login);

    if (result === true) {
      setTimeout(() => {
        this.router.navigate(["home"]);
      }, 750);
    } else {
      setTimeout(() => {
        this.isLoginButtonActive = true;
      }, 300);
    }
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

}
