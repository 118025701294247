import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OktebsComponent } from './oktebs.component';
import { RouterModule, Routes } from '@angular/router';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CoreCommonModule } from '@core/common.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AuthGuard } from 'app/login/guard/auth.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   canActivate: [AuthGuard],
  //   component: OktebsComponent,
  //   data: { animation: 'home' }
  // },
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: OktebsComponent,
    data: { animation: 'home' }
  },
  {
    path: 'work',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./work/work.module').then(m => m.WorkModule),
    data: { animation: 'work' }
  },
  {
    path: 'work-type',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./work-type/work-type.module').then(m => m.WorkTypeModule),
    data: { animation: 'work-type' }
  },
  {
    path: 'work-status',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./work-status/work-status.module').then(m => m.WorkStatusModule),
    data: { animation: 'work-status' }
  },
  {
    path: 'profile',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'user',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'todo',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./todo/todo.module').then(m => m.TodoModule)
  },
  {
    path: 'foundation',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./foundation/foundation.module').then(m => m.FoundationModule)
  },
  {
    path:'message',
    canActivateChild: [AuthGuard],
    loadChildren:()=>import('./message/message.module').then(m=>m.MessageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('../login/login.module').then(m => m.LoginModule)
  }
]




@NgModule({
  declarations: [
    OktebsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CardSnippetModule,
    CoreCommonModule,
    SwiperModule,
    FullCalendarModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
  ],
  exports: [
    OktebsComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

})
export class OktebsModule { }
