<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">

    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{user.firstName}} {{user.lastName}}</span><span class="user-status">Admin</span>
        </div>
        <span class="avatar"><img class="round" src="../../../../assets/images/users/{{user.imageUrl}}" height="40"
            width="40" /><span class="avatar-status-online"></span></span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profil</a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'"
            [class]="'mr-50'"></span> Çıkış</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>