<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2 d-flex flex-column align-items-center">
            <img src="../../../assets/vgm_logo.png" alt="brand-logo" style="max-width: 30%;" class="mb-2" />
        <div class="card mb-0 w-100">
            <div class="card-body">
                <a href="javascript:void(0);" class="brand-logo">
                    <h2 class="brand-text text-primary ml-1">VATEBS</h2>
                </a>

                <h4 class="card-title mb-1">Hoşgeldin 👋</h4>

                <form class="auth-login-form mt-2">
                    <div class="form-group">
                        <label for="login-email" class="form-label">Mail Adresi</label>
                        <input type="text" class="form-control" placeholder="mail@example.com"
                            aria-describedby="login-email" tabindex="1" autofocus name="txtMailAddress" #txtMailAddress="ngModel" [(ngModel)]="login.mailAddress"/>
                    </div>

                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label for="login-password">Şifre</label>
                            <a>
                                <small>Şifremi Unuttum</small>
                            </a>
                        </div>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType ? 'text' : 'password'"
                                class="form-control form-control-merge" tabindex="2" placeholder="·········"
                                aria-describedby="login-password" name="txtPassword" #txtPassword="ngModel" [(ngModel)]="login.password"/>

                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                        'icon-eye-off': passwordTextType,
                        'icon-eye': !passwordTextType
                      }" (click)="togglePasswordTextType()"></i></span>
                            </div>
                        </div>
                        <div class="invalid-feedback">
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block" tabindex="4" rippleEffect (click)="signIn()">Giriş Yap</button>
                </form>
            </div>
        </div>
    </div>
</div>