<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://www.vgm.gov.tr/" target="_blank">VAKIFLAR GENEL MÜDÜRLÜĞÜ </a>
    <span class="d-none d-sm-inline-block"> Tüm Hakları Saklıdır</span>
    <img src="../../../../assets/vgm_logo.png" style="max-height: 36px;" alt="">
  </span>
  <span class="float-md-right d-block d-md-inline-block mt-25">
    <a class="ml-25" href="https://www.vgm.gov.tr/" target="_blank">OSTİM TEKNİK ÜNİVERSİTESİ</a> tarafından hazırlanmıştır.
    <img src="../../../../assets/OstimTeknikLogo.png" style="max-height: 36px;" alt="">
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

