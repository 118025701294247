import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private helperService:ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // İşlem başarılı, burada gerekirse işlemler yapabilirsiniz.
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 400:
                this.helperService.error(error.error)
                break;
              case 403:
                this.helperService.error(error.error)
                break;
              case 404:
                this.helperService.error(error.error);
                break;
              case 405:
                this.helperService.error(error.error)
                break;
              case 415:
                this.helperService.error(error.error)
                break;
              case 500:
                this.helperService.error(error.error)
                break;
              default:
                console.log(`Bilinmeyen Hata: ${error.status}`);
                break;
            }
          }
        }
      )
    );
  }
}