import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Work } from '../model/work';
import { WorkList } from '../model/work-list';
import { WorkDetail } from '../model/wor-detail';
import { WorkFile } from '../model/work-file';
import { WorkRead } from '../model/work-read';
import { WorkReadDtoForMostRead } from '../model/work-read-for-most-read';

@Injectable({
  providedIn: 'root'
})
export class WorkService {

  constructor(@Inject("apiUrl") private apiUrl: string, private httpClient: HttpClient) { }

  getList(): Observable<WorkList[]> {
    return this.httpClient.get<WorkList[]>(this.apiUrl + "Works/GetList");
  }

  get(id: number): Observable<WorkDetail> {
    return this.httpClient.get<WorkDetail>(this.apiUrl + "Works/GetById/" + id);
  }

  getWorkReadListForCalendar():Observable<WorkRead[]>{
    return this.httpClient.get<WorkRead[]>(this.apiUrl+"WorkReads/GetList");
  }

  getMostReadWork():Observable<WorkReadDtoForMostRead>{
    return this.httpClient.get<WorkReadDtoForMostRead>(this.apiUrl+"WorkReads/GetMostReadWork");
  }

  getQrPreview(qrText:string): Observable<any>{
    return this.httpClient.get<any>(this.apiUrl + "Works/GetQrPreview/" + qrText);
  }

  add(work: any): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl + "Works/Add", work);
  }

  update(work: any): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl + "Works/Update/", work);
  }

  saveHistory(work:any):Observable<any>{
    return this.httpClient.post<any>(this.apiUrl+"Works/SaveHistory",work);
  }

  saveFile(work:any):Observable<any>{
    return this.httpClient.post<any>(this.apiUrl+"WorkFiles/Add",work);
  }

  getFiles(id:number):Observable<any>{
    return this.httpClient.get<any>(this.apiUrl+"WorkFiles/GetListByWorkId/"+id);
  }

  deleteFile(id:any):Observable<any>{
    return this.httpClient.post<any>(this.apiUrl+"WorkFiles/DeleteById",id);
  }

  delete(work: Work): Observable<WorkList> {
    return this.httpClient.post<WorkList>(this.apiUrl + "Works/DeleteWork",work);
  }

  addFromExcel(file:any):Observable<any>{
    return this.httpClient.post<any>(this.apiUrl+"Works/AddFromExcel",file);
  }

  addFromPdf(file:any):Observable<any>{
    return this.httpClient.post<any>(this.apiUrl+"Works/AddFromPdf",file);
  }

  addFromWord(file:any):Observable<any>{
    return this.httpClient.post<any>(this.apiUrl+"Works/AddFromWord",file);
  }

  addFromZip(file:any):Observable<any>{
    return this.httpClient.post<any>(this.apiUrl+"Works/AddFromZip",file);
  }

}
