import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Ana Sayfa',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'work',
    title: 'Eser Listesi',
    translate: 'MENU.WORK',
    type: 'item',
    icon: 'file',
    url: 'work'
  },
  {
    id: 'user',
    title: 'Kullanıcılar',
    translate: 'MENU.USER',
    type: 'item',
    icon: 'users',
    url: 'user'
  },
  // {
  //   id: 'work-type',
  //   title: 'Eser Tipleri',
  //   translate: 'MENU.WORK_TYPE',
  //   type: 'item',
  //   icon: 'list',
  //   url: 'work-type'
  // },
  // {
  //   id:'work-status',
  //   title:'Eser Durumları',
  //   translate:'MENU.WORK_STATUS',
  //   type:'item',
  //   icon:'tool',
  //   url:'work-status'
  // },
  // {
  //   id:'foundation',
  //   title:'Vakıflar',
  //   translate:'MENU.FOUNDATION',
  //   type:'item',
  //   icon:'globe',
  //   url:'foundation',
  // },
  {
    id: 'message',
    title: 'Mesajlar',
    translate: 'MENU.MESSAGE',
    type: 'item',
    icon: 'message-circle',
    url: 'message'
  }
]
