import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserList } from '../model/user-list';
import { Observable } from 'rxjs';
import { User } from '../model/user';
import { ProfilePassword } from 'app/oktebs/profile/model/profile-password';
import { ProfilePersonalInformation } from 'app/oktebs/profile/model/profile-personal-information';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(@Inject("apiUrl") private apiUrl: string, private httpClient: HttpClient) { }

  getList(): Observable<UserList[]> {
    return this.httpClient.get<UserList[]>(this.apiUrl + "Users/GetList");
  }

  get(id: number): Observable<User> {
    return this.httpClient.get<User>(this.apiUrl + "Users/GetById/" + id);
  }

  add(user: any): Observable<User> {
    return this.httpClient.post<User>(this.apiUrl + "Users/Add", user);
  }

  update(user: any): Observable<User> {
    return this.httpClient.post<User>(this.apiUrl + "Users/Update", user);
  }

  delete(user: UserList): Observable<UserList> {
    return this.httpClient.post<UserList>(this.apiUrl + "Users/Delete",user);
  }

  updatePersonalInformation(profile: ProfilePersonalInformation): Observable<ProfilePersonalInformation> {
    return this.httpClient.post<ProfilePersonalInformation>(this.apiUrl + "Users/UpdatePersonalInformation", profile);
  }

  updatePassword(profile: ProfilePassword): Observable<ProfilePassword> {
    return this.httpClient.post<ProfilePassword>(this.apiUrl + "Users/UpdatePassword", profile);
  }

  updatePhoto(profile: any): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl + "Users/UpdatePhoto", profile);
  }

}
