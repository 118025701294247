import { Component, ElementRef, OnInit } from '@angular/core';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { WorkService } from './work/service/work.service';
import { WorkRead } from './work/model/work-read';
import { WorkReadDtoForMostRead } from './work/model/work-read-for-most-read';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { WorkList } from './work/model/work-list';
import { WorkReadData } from './model/work-read';
import { debug } from 'console';

@Component({  
  selector: 'app-oktebs',
  templateUrl: './oktebs.component.html',
  styles: [
  ],
})
export class OktebsComponent implements OnInit {

  constructor(private workService:WorkService,private toasterService:ToastrService,private elRef: ElementRef) {}

  public contentHeader: object
  public basicSelectedOption: number = 10;

  works:WorkList[]=[];

  workReads:any[]=[];

  sehirler: any[] = [];

  public events: EventInput[]=[];

  workread: WorkReadData[] = [];

  public swiperCoverflow: SwiperConfigInterface = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true
    },
    pagination: {
      el: '.swiper-pagination'
    }
  };

  mostRead:WorkReadDtoForMostRead=new WorkReadDtoForMostRead();

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    locale: 'tr',
    events: this.events,
    buttonText: {
      today: 'Bugün',
      month: 'Ay',
      week: 'Hafta',
      day: 'Gün',
      list: 'Liste',
    },
  eventClick: this.handleEventClick.bind(this),
  };


  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'VATEBS',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Ana Sayfa',
            isLink: true,
            link: '/oktebs'
          }
        ]
      }
    } 

    this.workService.getList().subscribe((res:any)=>{
      this.works=res.data;
    })

    this.workService.getWorkReadListForCalendar().subscribe((res:any)=>{
       this.workReads=res.data;
       this.events = res.data.map((data: any) => ({
        title: `Toplam: ${data.totalRead}`,
        start: new Date(data.readDates[0]),
        allDay: true,
        workId: data.workId
      }));
    });
    this.workService.getMostReadWork().subscribe((res:any)=>{
      this.mostRead=res.data;
    })

  }

  handleEventClick(eventClickInfo: any) {
    let work;

    this.workService.get(eventClickInfo.event.extendedProps.workId).subscribe((res:any)=>{
      work = res.data;
      console.log(work);
      
          Swal.fire({
            title: work.title,
            text: eventClickInfo.event.title + " kere görüntülendi",
            icon: 'info',
            confirmButtonText: 'Görüntüle',
          }).then((result)=>{
            if(result.isConfirmed){
              window.open("https://oktebsuser.ostimteknikuni.com/oktebs/" + work.id, "_blank")
            }
          });
    })

  }

  svgturkiyeharitasi() {
    const element = document.querySelector("#svg-turkiye-haritasi");
    const info = document.querySelector(".il-isimleri") as HTMLElement;
    this.workService.getList().subscribe((data: any) => {
      this.sehirler = data.data;
      this.sehirler.forEach((entry: any) => {
        let ilAdi = entry.cityName.toLowerCase("tr-TR");
        ilAdi = ilAdi.charAt(0).toUpperCase() + ilAdi.slice(1);
        const elements = document.querySelectorAll(`[data-iladi="${ilAdi}"]`);
        elements.forEach((element) => {
          const paths = element.querySelectorAll("path");
          paths.forEach((path) => {
            path.style.fill = "orange"; // veya başka bir renk değeri
          });
        });
      });
    }, (error) => {
      console.error('Veri getirme hatası:', error);
    });



    element.addEventListener("mouseover", (event: any) => {
      if (
        event.target.tagName === "path" &&
        event.target.parentNode.id !== "guney-kibris"
      ) {
        const iladi = event.target.parentNode.getAttribute("data-iladi");
        info.innerHTML = `<div>${iladi}</div>`;
      }
    });

    element.addEventListener("mousemove", (event: any) => {
      info.style.top = event.pageY - 25 + "px";
      info.style.left = event.layerX + 30 + "px";
    });

    element.addEventListener("mouseout", () => {
      info.innerHTML = "";
    });

    element.addEventListener("click", (event: any) => {
      if (event.target.tagName === "path") {
        event.preventDefault();

        const parent = event.target.parentNode;
        const ilAdi = parent.getAttribute("data-iladi");
        const id = parent.getAttribute("id");
      }
    });
  }

}
